<template>
  <Page id="borderglobaltrade" anchor="borderglobaltrade">
    <ServicesCard
      anchor="borderglobaltrade-anchor"
      title="Borders and Global Trade"
      img="dockyard.webp"
      alt="Shipping Container Dockyard"
    >
      <p>
        Our highly experienced and skilled experts in the fields of border
        control strategies and solutions, trade strategy and solutions puts
        C-Clarity in the driving seat of change for our global public and
        private sector customers and partners.
      </p>
    </ServicesCard>
    <ServicesSplit
      id="borderglobaltrade-anchor"
      img="dockyard.webp"
      problem="The modern border is rapidly morphing into more than the traditional 'boundary' line where  controls on trade are managed and duties and taxes are collected. Geo-political uncertainty and lessons from the recent pandemic have thrust international trade border management into playing a vital role in maintaining national and international security as well as public health and safety."
      solution="The answer is to break the big picture into bite size chunks, identify the challenges which need to be addressed and develop innovative solutions which can be rapidly brought to market. These solutions require a range of capabilities, technology alone will not provide the answers in isolation. That's why we have built relationships with industry experts across multiple disciplines including trade law, customs policy and border operations, all essential to ensure technology solutions are able to deliver value in the real world."
    />
  </Page>
</template>

<script>
import ServicesSplit from "@/components/pages/services/ServicesSplit.vue";
import ServicesCard from "@/components/pages/services/ServicesCard.vue";
import Page from "@/components/core/Page.vue";

export default {
  components: {
    ServicesSplit,
    ServicesCard,
    Page,
  },
};
</script>

<style scoped>
</style>