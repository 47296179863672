<template>
  <div class="servicessplit" :class="mq">
    <div
      class="servicessplit-left servicessplit-side true-center"
      :style="computedImg"
      :class="{ 'servicessplit-shrink': index == 1 }"
      @mouseover="index = 0"
      @mouseleave="index = null"
    >
      <div class="img-overlay-dark true-center">
        <transition name="fade-slow">
          <div v-if="index == 0 || isMobile" class="servicessplit-main">
            <h1>Industry Challenge</h1>
            <p>{{ problem }}</p>
          </div>
          <div v-else>
            <h1>Industry Challenge</h1>
          </div>
        </transition>
      </div>
      <div v-if="isMobile" class="servicessplit-sizer">
        <h1>Industry Challenge</h1>
        <p>{{ problem }}</p>
      </div>
    </div>
    <!--  -->
    <!--  -->
    <!--  -->
    <div
      class="servicessplit-right servicessplit-side true-center"
      :class="{ 'servicessplit-shrink': index == 0 }"
      @mouseover="index = 1"
      @mouseleave="index = null"
    >
      <div class="img-overlay-dark true-center">
        <transition name="fade-slow">
          <div v-if="index == 1 || isMobile" class="servicessplit-main">
            <h1>Our Solution</h1>
            <p>{{ solution }}</p>
          </div>
          <div v-else>
            <h1>Our Solution</h1>
          </div>
        </transition>
      </div>
      <div v-if="isMobile" class="servicessplit-sizer">
        <h1>Our Solution</h1>
        <p>{{ solution }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "@/javascript/mixins.js";

export default {
  inject: ["mq"],
  mixins: [mixins],
  props: {
    problem: String,
    solution: String,
    img: String,
  },
  data() {
    return {
      index: null,
    };
  },
  computed: {
    computedImg() {
      return {
        "background-image":
          "url(" + require("@/assets/images/" + this.img) + ")",
      };
    },
  },
};
</script>

<style scoped>
.servicessplit {
  box-shadow: var(--box-shadow);
  align-items: stretch;
  min-height: 500px;
  display: flex;
  width: 100%;
}

.brMobile.servicessplit {
  flex-direction: column;
}

/* ================ */
/* Both Sides Styling */
/* ================ */
.servicessplit-side {
  transition: var(--animation-slow);
  flex-direction: column;
  cursor: pointer;
  color: white;
  padding: 60px;
  display: flex;
}

.brTablet .servicessplit-side,
.brTablet .servicessplit-main {
  padding: 40px;
}

.brMobile .servicessplit-side,
.brMobile .servicessplit-main {
  padding: 40px 40px 40px 40px;
  text-align: center;
  cursor: unset;
}

.servicessplit-side > div {
  backdrop-filter: blur(12px);
}

/* ================ */
/* Side Specific Styling */
/* ================ */
.servicessplit-left {
  background: var(--primary);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  flex: 1;
}

.servicessplit-right {
  background: var(--dark);
  position: relative;
  flex: 1;
}

.servicessplit:not(.brMobile) .servicessplit-side:hover {
  align-items: flex-start;
  gap: 20px;
  flex: 5;
}

/* ================ */
/* Content Styling */
/* ================ */
.servicessplit-main {
  justify-content: flex-start;
  flex-direction: column;
  padding: 60px;
  display: flex;
  width: 100%;
}

.servicessplit-side h1 {
  transition: var(--animation-slow);
  padding: 10px 0px 10px 0px;
  white-space: nowrap;
  font-size: 40px;
}

.servicessplit:not(.brMobile) .servicessplit-shrink h1 {
  font-size: var(--font-big);
  transform: rotate(-90deg);
  padding: unset;
}

.servicessplit-side p {
  font-size: 20px;
}

.servicessplit-sizer {
  visibility: hidden;
}
</style>