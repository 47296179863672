<template>
  <section class="servicescard" :class="mq">
    <div class="servicescard-left">
      <h1 class="servicescard-title">
        {{ title }}
      </h1>
      <div class="servicescard-text">
        <slot></slot>
      </div>
      <Button v-if="!isMobile" value="Continue" @click="scrollTo" />
    </div>
    <div class="servicescard-right">
      <img :src="getImg" :alt="alt" class="servicescard-img" />
      <Dots :left="100" :top="-20" />
      <Dots :bottom="-20" :left="-40" />
    </div>
  </section>
</template>

<script>
import Button from "@/components/global/Button.vue";
import mixins from "@/javascript/mixins.js";
import Dots from "@/assets/shapes/Dots.vue";

export default {
  inject: ["mq"],
  mixins: [mixins],
  props: {
    anchor: String,
    title: String,
    img: String,
    alt: String,
  },
  components: { Button, Dots },
  computed: {
    getImg() {
      return require("@/assets/images/" + this.img);
    },
  },
  methods: {
    scrollTo() {
      var el = document.getElementById(this.anchor);
      var headerOffset = 220;

      if (el) {
        var elementPosition = el.getBoundingClientRect().top;
        var offsetPosition =
          elementPosition + window.pageYOffset - headerOffset;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>

<style scoped>
.servicescard {
  display: flex;
  width: 100%;
  gap: 60px;
}

.brMobile.servicescard {
  flex-direction: column;
  gap: 40px;
}

.servicescard-left {
  justify-content: center;
  flex-direction: column;
  display: flex;
  gap: 40px;
  flex: 1;
}

.servicescard-text {
  flex-direction: column;
  text-align: justify;
  display: flex;
  gap: 20px;
}

.brMobile .servicescard-text {
  text-align: start;
}

.servicescard-right {
  position: relative;
  height: 400px;
  width: 50%;
  flex: 1;
}

.brMobile .servicescard-right {
  height: 300px;
  width: 100%;
}

.servicescard-img {
  box-shadow: var(--box-shadow);
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.servicescard-title {
  color: var(--accent);
}
</style>
